import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PageRegisterComponent } from './authentication/page-register/page-register.component';
import { RefComponent } from './ref/ref.component';
import { AuthGuardService } from './auth-guard.service';

export const routes: Routes = [
    { path: '', redirectTo: 'authentication', pathMatch: 'full' },
    { path: 'r/:data', component: RefComponent, data: { title: 'Register :: Citizens Raffle' } },

    { path: 'admin',   canActivate: [AuthGuardService], loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule) },
    { path: 'authentication', loadChildren: () => import('app/authentication/authentication.module').then(m => m.AuthenticationModule) }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: false });