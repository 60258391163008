export const environment = {
  production: true,
  serverUrl: "https://clownfish-app-ys3sj.ondigitalocean.app/",
  log: false,
  flags: {
    useNewHeader: false
  }
};

//  mongodb://adminUserCitizen:citizens@db.pass@http://159.65.88.143:27017/db?authSource=admin
//  mongodump --host CDLLDb-shard-0/cdlldb-shard-00-00-c7hua.mongodb.net:27017,cdlldb-shard-00-01-c7hua.mongodb.net:27017,cdlldb-shard-00-02-c7hua.mongodb.net:27017 --ssl --username testUser --password 6k3xrQvGQWffCCBU --authenticationDatabase admin --db test
//  mongorestore --host 159.65.88.143:27017  --a
